<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-lh">
        <div class="data clh_l">
          <h3>{{rowsData[0].title}}</h3>
          <ul>
            <li v-for="ele in rowsData[0].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash lianTip">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <div class="data clh_h">
          <h3>{{rowsData[1].title}}</h3>
          <ul>
            <li v-for="ele in rowsData[1].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>

        </div>

      </div>
      <yushe />
      <div class="roadmap">
        <!-- 表头 -->
        <table class="ltbinfo">
          <tbody>
            <tr>
              <template v-for="(item,index) in ltTitleArr">
                <th v-if="index<=13" @click="changeQiu(item.index)" :class="item.index==qiuIndex?'selected':''" :key="item.title">{{item.title}}</th>

              </template>

            </tr>
            <tr>
              <template v-for="(item,index) in ltTitleArr">
                <th v-if="index>13" @click="changeQiu(item.index)" :class="item.index==qiuIndex?'selected':''" :key="item.title">{{item.title}}</th>

              </template>

            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
        <table colspan="0" id="lt_lh_b1-b2" cellspan="0" class="lttab" style="display: table">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
      </div>
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lm',
      ltArr: [],
      ltTitleArr: [
        { title: "1-2", index: 1 },
        { title: "1-3", index: 2 },
        { title: "1-4", index: 3 },
        { title: "1-5", index: 4 },
        { title: "1-6", index: 5 },
        { title: "1-7", index: 6 },
        { title: "1-8", index: 7 },
        { title: "2-3", index: 8 },
        { title: "2-4", index: 9 },
        { title: "2-5", index: 10 },
        { title: "2-6", index: 11 },
        { title: "2-7", index: 12 },
        { title: "2-8", index: 13 },
        { title: "3-4", index: 14 },
        { title: "3-5", index: 15 },
        { title: "3-6", index: 16 },
        { title: "3-7", index: 17 },
        { title: "3-8", index: 18 },
        { title: "4-5", index: 19 },
        { title: "4-6", index: 20 },
        { title: "4-7", index: 21 },
        { title: "4-8", index: 22 },
        { title: "5-6", index: 23 },
        { title: "5-7", index: 24 },
        { title: "5-8", index: 25 },
        { title: "6-7", index: 26 },
        { title: "6-8", index: 27 },
        { title: "7-8", index: 28 },
      ],
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "龙", arr: [] },
        { title: "虎", arr: [] },
      ],
      labelArr1: [
        "1-2",
        "1-3",
        "1-4",
        "1-5",
        "1-6",
        "1-7",
        "1-8",
        "2-3",
        "2-4",
        "2-5",
        "2-6", // 还没有
        "2-7",
        "2-8",
        "3-4",
        "3-5", // 还没有
        "3-6",
        "3-7",
        "3-8", // 还没有
        "4-5",
        "4-6",
        "4-7",
        "4-8",
        "5-6",
        "5-7",
        "5-8",
        "6-7",
        "6-8",
        "7-8",
      ],
      lutu1: {
        arr1: [[], [], [], [], []],
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu3: {
        arr1: [[], [], [], [], []], //单
      },

      lutu4: {
        arr1: [[], [], [], [], []],
      },

      lutu5: {
        arr1: [[], [], [], [], []],
      },
      lutu6: {
        arr1: [[], [], [], [], []],
      },

      lutu7: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu8: {
        arr1: [[], [], [], [], []], //单
      },

      lutu9: {
        arr1: [[], [], [], [], []],
      },

      lutu10: {
        arr1: [[], [], [], [], []],
      },
      lutu11: {
        arr1: [[], [], [], [], []],
      },
      lutu12: {
        arr1: [[], [], [], [], []],
      },
      lutu13: {
        arr1: [[], [], [], [], []],
      },
      lutu14: {
        arr1: [[], [], [], [], []],
      },
      lutu15: {
        arr1: [[], [], [], [], []],
      },
      lutu16: {
        arr1: [[], [], [], [], []],
      },
      lutu17: {
        arr1: [[], [], [], [], []],
      },
      lutu18: {
        arr1: [[], [], [], [], []],
      },
      lutu19: {
        arr1: [[], [], [], [], []],
      },
      lutu20: {
        arr1: [[], [], [], [], []],
      },
      lutu21: {
        arr1: [[], [], [], [], []],
      },
      lutu22: {
        arr1: [[], [], [], [], []],
      },
      lutu23: {
        arr1: [[], [], [], [], []],
      },
      lutu24: {
        arr1: [[], [], [], [], []],
      },
      lutu25: {
        arr1: [[], [], [], [], []],
      },
      lutu26: {
        arr1: [[], [], [], [], []],
      },
      lutu27: {
        arr1: [[], [], [], [], []],
      },
      lutu28: {
        arr1: [[], [], [], [], []],
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 龙
        let arr1 = this.ddFilterData1(
          resAArr,
          [
            3880, 3882, 3884, 3886, 3888, 3890, 1697, 3892, 3894, 3896, 3898,
            1699, 3900, 3902, 3904, 1701, 3906, 3908, 1703, 3910, 3912, 3914,
            3916, 3918, 3920, 3922, 3924, 3926,
          ],
          this.labelArr1
        );
        this.rowsData[0].arr = arr1;
        // 虎
        let arr2 = this.ddFilterData1(
          resAArr,
          [
            3881, 3883, 3885, 3887, 3889, 3891, 1698, 3893, 3895, 3897, 3899,
            1700, 3901, 3903, 3905, 1702, 3907, 3909, 1704, 3911, 3913, 3915,
            3917, 3919, 3921, 3923, 3925, 3927,
          ],
          this.labelArr1
        );
        this.rowsData[1].arr = arr2;
        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu6 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu7 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu8 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu9 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu10 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu11 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu12 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu13 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu14 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu15 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu16 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu17 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu18 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu19 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu20 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu21 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu22 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu23 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu24 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu25 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu26 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu27 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu28 = {
        arr1: [[], [], [], [], []],
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]],
              arr4 = [[]],
              arr5 = [[]],
              arr6 = [[]],
              arr7 = [[]],
              arr8 = [[]],
              arr9 = [[]],
              arr10 = [[]],
              arr11 = [[]],
              arr12 = [[]],
              arr13 = [[]],
              arr14 = [[]],
              arr15 = [[]],
              arr16 = [[]],
              arr17 = [[]],
              arr18 = [[]],
              arr19 = [[]],
              arr20 = [[]],
              arr21 = [[]],
              arr22 = [[]],
              arr23 = [[]],
              arr24 = [[]],
              arr25 = [[]],
              arr26 = [[]],
              arr27 = [[]],
              arr28 = [[]];

            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");

              // 1-2-----------------------

              this.initlutuData(arr1, this.bijiaolh(element.codeArr, 1, 2));
              this.initlutuData(arr2, this.bijiaolh(element.codeArr, 1, 3));
              this.initlutuData(arr3, this.bijiaolh(element.codeArr, 1, 4));
              this.initlutuData(arr4, this.bijiaolh(element.codeArr, 1, 5));
              this.initlutuData(arr5, this.bijiaolh(element.codeArr, 1, 6));
              this.initlutuData(arr6, this.bijiaolh(element.codeArr, 1, 7));
              this.initlutuData(arr7, this.bijiaolh(element.codeArr, 1, 8));
              this.initlutuData(arr8, this.bijiaolh(element.codeArr, 2, 3));
              this.initlutuData(arr9, this.bijiaolh(element.codeArr, 2, 4));
              this.initlutuData(arr10, this.bijiaolh(element.codeArr, 2, 5));
              this.initlutuData(arr11, this.bijiaolh(element.codeArr, 2, 6));
              this.initlutuData(arr12, this.bijiaolh(element.codeArr, 2, 7));
              this.initlutuData(arr13, this.bijiaolh(element.codeArr, 2, 8));
              this.initlutuData(arr14, this.bijiaolh(element.codeArr, 3, 4));
              this.initlutuData(arr15, this.bijiaolh(element.codeArr, 3, 5));
              this.initlutuData(arr16, this.bijiaolh(element.codeArr, 3, 6));
              this.initlutuData(arr17, this.bijiaolh(element.codeArr, 3, 7));
              this.initlutuData(arr18, this.bijiaolh(element.codeArr, 3, 8));
              this.initlutuData(arr19, this.bijiaolh(element.codeArr, 4, 5));
              this.initlutuData(arr20, this.bijiaolh(element.codeArr, 4, 6));
              this.initlutuData(arr21, this.bijiaolh(element.codeArr, 4, 7));
              this.initlutuData(arr22, this.bijiaolh(element.codeArr, 4, 8));
              this.initlutuData(arr23, this.bijiaolh(element.codeArr, 5, 6));
              this.initlutuData(arr24, this.bijiaolh(element.codeArr, 5, 7));
              this.initlutuData(arr25, this.bijiaolh(element.codeArr, 5, 8));
              this.initlutuData(arr26, this.bijiaolh(element.codeArr, 6, 7));
              this.initlutuData(arr27, this.bijiaolh(element.codeArr, 6, 8));
              this.initlutuData(arr28, this.bijiaolh(element.codeArr, 7, 8));

              // ----------------------------------------------------------
            });

            this.buquanArr(arr1, this.lutu1.arr1);
            this.buquanArr(arr2, this.lutu2.arr1);
            this.buquanArr(arr3, this.lutu3.arr1);
            this.buquanArr(arr4, this.lutu4.arr1);
            this.buquanArr(arr5, this.lutu5.arr1);
            this.buquanArr(arr6, this.lutu6.arr1);
            this.buquanArr(arr7, this.lutu7.arr1);
            this.buquanArr(arr8, this.lutu8.arr1);
            this.buquanArr(arr9, this.lutu9.arr1);
            this.buquanArr(arr10, this.lutu10.arr1);
            this.buquanArr(arr11, this.lutu11.arr1);
            this.buquanArr(arr12, this.lutu12.arr1);
            this.buquanArr(arr13, this.lutu13.arr1);
            this.buquanArr(arr14, this.lutu14.arr1);
            this.buquanArr(arr15, this.lutu15.arr1);
            this.buquanArr(arr16, this.lutu16.arr1);
            this.buquanArr(arr17, this.lutu17.arr1);
            this.buquanArr(arr18, this.lutu18.arr1);
            this.buquanArr(arr19, this.lutu19.arr1);
            this.buquanArr(arr20, this.lutu20.arr1);
            this.buquanArr(arr21, this.lutu21.arr1);
            this.buquanArr(arr22, this.lutu22.arr1);
            this.buquanArr(arr23, this.lutu23.arr1);
            this.buquanArr(arr24, this.lutu24.arr1);
            this.buquanArr(arr25, this.lutu25.arr1);
            this.buquanArr(arr26, this.lutu26.arr1);
            this.buquanArr(arr27, this.lutu27.arr1);
            this.buquanArr(arr28, this.lutu28.arr1);

            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          }
        } else {
        }
      });
    },
    bijiaolh(arr, num1, num2) {
      let str = "";
      if (arr[num1 - 1] > arr[num2 - 1]) {
        str = "龙";
      } else {
        str = "虎";
      }
      return str;
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain > .data > ul > li {
  height: 26px;
  min-height: 26px;
}
</style>